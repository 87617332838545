// Copyright 2024, Imprivata, Inc.  All rights reserved.

import { QueryParam } from './constants';
import { IcpFlow } from './containers/icp-flow/IcpFlow';
import { MfaFlow } from './containers/mfa-flow/MfaFlow';

export const App: React.FC = () => {
  const isFaceFactor =
    new URLSearchParams(window.location.search)
      .get(QueryParam.AcrValues)
      ?.includes('face') || false;

  return isFaceFactor ? <IcpFlow /> : <MfaFlow />;
};
