// Copyright 2024, Imprivata, Inc.  All rights reserved.

import { useTranslation } from 'react-i18next';
import { AuthnModule, EventType, FactorOption } from '@imprivata-cloud/authn';
import { tracer } from '../../tracing';
import { WEB_APP_NAME } from '../../shared/constants';
import { AppBackground } from '../../components/AppBackground';
import { QueryParam } from '../../constants';
import { handleAuthnArtifact, setDefaultHeader } from '../../api/api';
import { useEffect } from 'react';
import { ImprHeaders } from '@imprivata-cloud/common';
import { jsonToBase64 } from '@imprivata-cloud/data-privacy-js';

export const IcpFlow: React.FC = () => {
  const { t } = useTranslation();
  const queryParams = new URLSearchParams(window.location.search);

  const tenantId = queryParams.get(QueryParam.TenantId) || '';
  const rpRequestId = queryParams.get(QueryParam.RpRequestId) || '';
  const contextResource = {
    resourceType: queryParams.get(QueryParam.ResourceType) || '',
    contextType: queryParams.get(QueryParam.ContextType) || '',
  };
  const username = queryParams.get(QueryParam.LoginHint) || '';
  const codeChallenge = queryParams.get(QueryParam.CodeChallenge) || '';
  const codeChallengeMethod =
    queryParams.get(QueryParam.CodeChallengeMethod) || '';
  const oidcRequestData = {
    codeChallenge,
    codeChallengeMethod,
  };

  useEffect(() => {
    setDefaultHeader(
      ImprHeaders.ImprClientName,
      jsonToBase64({
        name: WEB_APP_NAME,
      }),
    );

    setDefaultHeader(ImprHeaders.ImprTenantId, tenantId);
  });

  const onSuccess = async (authorizationCode: string) => {
    const {
      redirectUri,
      authorizationCode: responseAuthorizationCode,
      state,
    } = await handleAuthnArtifact({
      rpRequestId,
      authorizationCode,
    });

    const stateParam = state ? `&state=${state}` : '';
    window.location.href = `${redirectUri}?code=${responseAuthorizationCode}${stateParam}`;
  };

  return (
    <AppBackground title={t('layout.header')}>
      <AuthnModule
        contextResource={contextResource}
        tenantId={tenantId}
        onEvent={({ event, data }) => {
          console.log('[AUTHN MODULE EVENT]', event, data);

          if (event === EventType.AUTHENTICATED) {
            onSuccess(data?.oidcAuthorizationCode || '');
          }
        }}
        factors={[{ factorType: 'face' } as FactorOption]}
        requestConfig={{
          clientName: WEB_APP_NAME,
          username,
          oidcRequestData,
        }}
        hideAddAnotherMethod
        tracing={{
          workflowId: tracer.getTraceContext().workflowId || '',
        }}
      />
    </AppBackground>
  );
};
